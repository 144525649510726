<!-- Notifications toggle -->
<button
    mat-icon-button
    [matBadge]="userBadges()?.userBadge > 0 ? userBadges()?.userBadge : null"
    matBadgeColor="warn"
    matBadgePosition="before"
    matBadgeSize="small"
    (click)="clickNotification()">
    <mat-icon [svgIcon]="'feather:bell'"></mat-icon>
</button>

