import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { MatBadgeModule } from "@angular/material/badge";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ENotificationActionKey } from "app/core/enums/communication.enum";
import { ErrorHelper } from "app/core/helpers/error.helper";
import { TimeTranslateHelper } from "app/core/helpers/time-translate.helper";
import { UrlHelper } from "app/core/helpers/url.helper";
import { isLastPage } from "app/core/helpers/util";
import { IParamPagination } from "app/core/models/api/pagination";
import { IUserNotification } from "app/core/models/user/user";
import { AuthenticationService } from "app/core/services/authentication/authentication.service";
import { CommunicationService } from "app/core/services/communication/communication.service";
import { SidebarService } from "app/layout/components/sidebar/sidebar.service";
import { EmptyComponent, LoadingComponent, ToastrService } from '@kiwid-app/kiwid-ui-lib-angular';
import { AvatarModule } from "ngx-avatars";
import { first } from "rxjs/operators";
import { BaseComponent } from "../../base/base.component";
import { EAclFunction, EAclModule } from "app/core/permissions/enum/access.enum";
import { KiwidPermissionsService } from "app/core/permissions/service/kiwid-permissions.service";
import { locale as english } from "./i18n/en";
import { locale as spanish } from "./i18n/es";
import { locale as portuguese } from "./i18n/pt";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { HashHelper } from 'app/core/helpers/hash.helper';

@Component({
    standalone: true,
    selector: 'app-notification-user',
    templateUrl: './notification-user.component.html',
    styleUrls: ['./notification-user.component.scss'],
    imports: [
        LoadingComponent,
        EmptyComponent,
        MatBadgeModule,
        TranslateModule,
        RouterModule,
        AvatarModule,
        MatProgressSpinnerModule,
        NgxSkeletonLoaderModule,
    ],
})
export class NotificationUserComponent extends BaseComponent implements OnInit {
    readonly sidebarService = inject(SidebarService);
    private readonly timeTranslateHelper = inject(TimeTranslateHelper);
    private readonly communicationService = inject(CommunicationService);
    private readonly toastrService = inject(ToastrService);
    private readonly authenticationService = inject(AuthenticationService);
    private readonly kiwidPermissionsService = inject(KiwidPermissionsService);
    private readonly urlHelper = inject(UrlHelper);

    protected readonly isLoading = signal(true);
    protected readonly isLoadingPage = signal(false);
    protected readonly skeletonCount = signal([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    private readonly userNotifications = signal<IUserNotification[]>([]);
    protected readonly notifications = computed(() => {
        return this.userNotifications().map((item) => ({
            title: item.title,
            message: item.message,
            action: {
                text: this.getTextNotificationLink(item),
                routerLink: this.getRouterLinkNotificationLink(item),
                routerLinkQueryParam: this.getRouterLinkNotificationLinkQueryParam(item),
            },
            laboratory: item.laboratory?.legalName ?? '-',
            date: this.timeTranslateHelper.getTimeTranslate(item.createdAt),
            avatar: !!item.laboratory
                ? item.laboratory?.logo.thumbnail ?? item.laboratory?.logo?.url ?? null
                : item.image?.thumbnail,
        }));
    });
    private isLastPage = signal(false);
    private paramPagination = signal<IParamPagination>({ offset: 0, limit: 20 });

    constructor() {
        super();
        this.registerOnTranslate('TOOLBAR_NOTIFICATION_USER', portuguese, english, spanish, false);
        this.getAllNotifications();
        this.subscriptions.add(
            this.sidebarService.scrollEvent.subscribe((event) => {
                this.scrollEvent(event);
            }),
        );
    }

    ngOnInit(): void {
    }

    getRouterLinkNotificationLink(notification: IUserNotification): string | null {
        if (
            notification.key === ENotificationActionKey.ORDER_DETAIL &&
            this.kiwidPermissionsService.hasPermission({
                routeAccess: [
                    {
                        mod: EAclModule.ORDER,
                        fns: [EAclFunction.LIST],
                    },
                ],
            })
        ) {
            return `/production/order/detail/${notification.property?.orderId}/information`;
        }

        if (
            notification.key === ENotificationActionKey.ORDER_RATING &&
            this.kiwidPermissionsService.hasPermission({
                routeAccess: [
                    {
                        mod: EAclModule.ORDER_RATING,
                        fns: [EAclFunction.LIST],
                    },
                ],
            })
        ) {
            return `/production/order/detail/${notification.property?.orderId}/information`;
        }

        return null;
    }

    getRouterLinkNotificationLinkQueryParam(notification: IUserNotification): object {
        const timestamp = { t: `${Date.now()}_${HashHelper.uuidv4()}` };
        if (notification.key === ENotificationActionKey.ORDER_RATING) {
            return {
                rating: true,
                ...timestamp,
            };
        }

        if (notification.property?.filter) {
            return {
                ...notification.property?.filter,
                ...timestamp,
            };
        }

        return { ...timestamp };
    }

    getTextNotificationLink(notification: IUserNotification): string | null {
        if (notification.key === ENotificationActionKey.ORDER_DETAIL) {
            return this.translateKey('SEE_ORDER');
        }

        if (notification.key === ENotificationActionKey.ORDER_RATING) {
            return this.translateKey('SEE_ORDER_RATING');
        }

        if (notification.key === ENotificationActionKey.CUSTOMER_RELATIONSHIP) {
            return this.translateKey('SEE_CUSTOMER_RELATIONSHIP');
        }

        if (notification.key === ENotificationActionKey.PRODUCT_REORDER_POINT) {
            return this.translateKey('SEE_PRODUCT');
        }

        return null;
    }

    scrollEvent(event): void {
        const element = event.target as Element;
        if (element.scrollHeight - element.clientHeight <= element.scrollTop + 10) {
            if (!this.isLoading() && !this.isLoadingPage() && !this.isLastPage()) {
                this.paramPagination.update((item) => ({ ...item, offset: this.userNotifications().length ?? 0 }));
                this.getAllNotifications();
            }
        }
    }

    private generateItem(item: IUserNotification): IUserNotificationWithDate {
        // TODO: Priolo verificar o timezone
        return {
            ...item,
            // date: this.timeTranslateHelper.getTimeTranslate(this.authenticationService.locationDefault().id, item.createdAt),
            date: item.createdAt,
            avatar: !!item.laboratory
                ? item.laboratory?.logo.thumbnail ?? item.laboratory?.logo?.url ?? null
                : item.image?.thumbnail,
        };
    }

    private getAllNotifications(): void {
        this.isLoadingPage.set(true);
        this.subscriptions.add(
            this.communicationService
                .getAllNotifications(this.paramPagination())
                .pipe(first())
                .subscribe({
                    next: ({ data, meta }) => {
                        this.isLastPage.set(isLastPage(meta));

                        if (meta.pagination.offset === 0) {
                            this.userNotifications.set([]);
                        }

                        this.userNotifications.update((items) => (
                            [ ...items, ...data ]
                        ));

                        this.isLoading.set(false);
                        this.isLoadingPage.set(false);
                    },
                    error: (error) => {
                        ErrorHelper.setToastrError(this.toastrService, error);
                        this.isLoading.set(false);
                        this.isLoadingPage.set(false);
                    },
                }),
        );
    }
}

export interface IUserNotificationWithDate extends IUserNotification {
    date: string;
    avatar?: string;
}
