import { environment } from '../../environments/environment';

export const locale = {
    lang: 'en',
    data: {
        APP: {
            TITLE: environment.titleBase,
            MODAL: {
                FEATURES: {
                    TITLE: "What's New",
                    BUTTONS: {
                        READ_LATER: 'Read Later',
                        OK: 'OK, Got It',
                        READ_ALL: 'Read All',
                    },
                },
                SEARCH_ORDER: {
                    TITLE: 'Search Order',
                    BUTTONS: {
                        SEARCH: 'Search',
                        CANCEL: 'Cancel',
                    },
                },
                PEOPLE: {
                    TITLE: 'Search person',
                    BUTTONS: {
                        CANCEL: 'Cancel',
                    },
                },
                CHUNK_ERROR: {
                    TITLE: 'System Update',
                    DESCRIPTION: 'A new version of the system is available. Please reload the page to update.',
                    BUTTONS: {
                        CONFIRM: 'Reload',
                    }
                }
            },
        },
    },
};
