<!-- Messages toggle -->
@if (hasPermissionChat()) {
    <button
        mat-icon-button
        [matBadge]="userBadges()?.chatBadge > 0 ? userBadges()?.chatBadge : null"
        matBadgeColor="warn"
        matBadgePosition="before"
        matBadgeSize="small"
        (click)="clickChat()">
        <mat-icon svgIcon="feather:message-square"></mat-icon>
    </button>
}


