import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { locale as portuguese } from './i18n/pt';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { KDateAdd, KDateDifferenceIn, KDateFormatTz, KDateIs } from '@kiwid-app/kiwid-date-typescript';
import { FuseTranslationLoaderService } from '../translate/translation-loader.service';
import { TimezoneService } from 'app/core/services/timezone/timezone.service';
import { Util } from 'app/core/helpers/util';

@Injectable({
    providedIn: 'root',
})
export class TimeTranslateHelper implements OnDestroy {
    private readonly subscriptions = new Subscription();
    private translate: any;

    constructor(
        private readonly translateService: TranslateService,
        private readonly fuseTranslationLoaderService: FuseTranslationLoaderService,
        private readonly timezoneService: TimezoneService,
    ) {
        this.registerTranslate();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    generateRangeDate(startDate: Date, endDate: Date, type: 'days' | 'months' | 'years', amount: number): Date[] {
        const dates: Date[] = [];
        let currentDate: Date = startDate;

        while (!KDateIs(currentDate, 'after', endDate)) {
            dates.push(currentDate);
            currentDate = KDateAdd(currentDate, type, amount);
        }

        return dates;
    }

    getTimeTranslate(dateTz: string): string {
        const seconds = KDateDifferenceIn(new Date(dateTz), new Date(), 'seconds');
        const minutes = seconds / 60;
        const hours = minutes / 60;

        if (seconds <= 59) {
            if (seconds === 1) {
                return this.translate?.FORMAT_SECOND?.replace('{{ seconds }}', seconds.toFixed(0));
            }
            return this.translate?.FORMAT_SECONDS?.replace('{{ seconds }}', seconds.toFixed(0));
        }

        if (minutes <= 59) {
            if (minutes === 1) {
                return this.translate?.FORMAT_MINUTE?.replace('{{ minutes }}', minutes.toFixed(0));
            }
            return this.translate?.FORMAT_MINUTES?.replace('{{ minutes }}', minutes.toFixed(0));
        }

        if (hours <= 59) {
            if (hours === 1) {
                return this.translate?.FORMAT_HOUR?.replace('{{ hours }}', hours.toFixed(0));
            }
            return this.translate?.FORMAT_HOURS?.replace('{{ hours }}', hours.toFixed(0));
        }

        return KDateFormatTz(new Date(dateTz), Intl.DateTimeFormat().resolvedOptions().timeZone, this.translate?.FORMAT_DATE_WITH_HOUR, { lang: Util.currentLang() });
    }

    getTimeTranslateLocation(locationId: string, dateTz: string): string {
        const seconds = KDateDifferenceIn(new Date(dateTz), new Date(), 'seconds');
        const minutes = seconds / 60;
        const hours = minutes / 60;

        if (seconds <= 59) {
            if (seconds === 1) {
                return this.translate?.FORMAT_SECOND?.replace('{{ seconds }}', seconds.toFixed(0));
            }
            return this.translate?.FORMAT_SECONDS?.replace('{{ seconds }}', seconds.toFixed(0));
        }

        if (minutes <= 59) {
            if (minutes === 1) {
                return this.translate?.FORMAT_MINUTE?.replace('{{ minutes }}', minutes.toFixed(0));
            }
            return this.translate?.FORMAT_MINUTES?.replace('{{ minutes }}', minutes.toFixed(0));
        }

        if (hours <= 59) {
            if (hours === 1) {
                return this.translate?.FORMAT_HOUR?.replace('{{ hours }}', hours.toFixed(0));
            }
            return this.translate?.FORMAT_HOURS?.replace('{{ hours }}', hours.toFixed(0));
        }

        return this.timezoneService.formatToTimezoneByLocation(locationId, new Date(dateTz), this.translate?.FORMAT_DATE_WITH_HOUR);
    }

    private registerTranslate(): void {
        this.fuseTranslationLoaderService.loadTranslations(portuguese, english, spanish);
        const subscription = this.translateService.get('HELPER.TIME').subscribe((response: any) => {
            this.translate = response;
        });

        this.subscriptions.add(subscription);
    }
}
