import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IDataPagination } from '../../models/api/data';
import { IParamPagination } from '../../models/api/pagination';
import { IError } from '../../models/error/error';
import { IGetUserBadges, IUserBadges, IUserNotification } from '../../models/user/user';
import { SseService } from '../sse/sse.service';

export const urlGetAllNotifications = () => ['clinic', 'admin', 'communication', 'notification'];
export const urlGetAllSystemMessages = () => ['lab', 'admin', 'communication', 'message'];
export const urlGetAllSystemFeatures = () => ['lab', 'admin', 'communication', 'feature'];
export const urlMarkFeaturesEmployee = () => ['lab', 'admin', 'communication', 'feature', 'mark'];
export const urlMarkAllFeaturesEmployee = () => ['lab', 'admin', 'communication', 'feature', 'mark-all'];

@Injectable({
    providedIn: 'root',
})
export class CommunicationService {
    private readonly router = inject(Router);
    private readonly httpClient = inject(HttpClient);
    private readonly sseService = inject(SseService);

    // CLINIC
    getAllNotifications(data: IParamPagination): Observable<IDataPagination<IUserNotification[]>> {
        const url = this.router.createUrlTree(urlGetAllNotifications(), {
            queryParams: data,
        });

        const parsedUrl = `${environment.apiUrl}/${environment.apiVersion}${url}`;

        return this.httpClient.get(parsedUrl, { responseType: 'json' }).pipe(
            map((res: IDataPagination<IUserNotification[]>) => {
                return res;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof ErrorEvent) {
                    return throwError(() => `Error: ${error.error.message}`);
                }
                const errorApi: IError = error.error.error;
                return throwError(() => errorApi);
            }),
        );
    }

    getEventSourceBadges(data: IGetUserBadges): [Observable<MessageEvent<IUserBadges>>, EventSource] {
        const baseUrl = ['clinic', 'admin', 'communication', 'badge'];

        const url = this.router.createUrlTree(baseUrl, {
            queryParams: data,
        });

        const parsedUrl = `${environment.apiUrlCommunication}/${environment.apiVersion}${url}`;

        return this.sseService.getServerSentEvent<IUserBadges>(parsedUrl);
    }
}
