export const locale = {
    lang: 'es',
    data: {
        CHAT: {
            SIDEBAR: {
                TITLE: 'Chat',
            },
        },
    },
};
