export const locale = {
    lang: 'pt',
    data: {
        CHAT: {
            SIDEBAR: {
                TITLE: 'Chat',
            },
        },
    },
};
