import { environment } from '../../environments/environment';

export const locale = {
    lang: 'pt',
    data: {
        APP: {
            TITLE: environment.titleBase,
            MODAL: {
                FEATURES: {
                    TITLE: 'Novidades',
                    BUTTONS: {
                        READ_LATER: 'Ver mais tarde',
                        OK: 'Ok entendi',
                        READ_ALL: 'Ler todas',
                    },
                },
                SEARCH_ORDER: {
                    TITLE: 'Buscar pedido',
                    BUTTONS: {
                        SEARCH: 'Buscar',
                        CANCEL: 'Cancelar',
                    },
                },
                PEOPLE: {
                    TITLE: 'Buscar pessoa',
                    BUTTONS: {
                        CANCEL: 'Cancelar',
                    },
                },
                CHUNK_ERROR: {
                    TITLE: 'Atualização disponível',
                    DESCRIPTION: 'Realizamos uma atualização no sistema e é necessário recarregar a pagina.',
                    BUTTONS: {
                        CONFIRM: 'Ok, recarregar',
                    }
                }
            },
        },
    },
};
