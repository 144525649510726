export const locale = {
    lang: 'en',
    data: {
        USER: {
            MENU: {
                CHANGE: 'Trocar',
                LOGOUT: 'Sair',
                PLAN_PAYMENTS: 'Planos e pagamentos',
                LOCATION_STATUS: {
                    TRIAL_EXPIRES: 'TRIAL EXPIRA EM {{ days }} DIAS. ADQUIRA!',
                    TRIAL_EXPIRES_ONE_DAY: 'TRIAL EXPIRA EM {{ days }} DIA. ADQUIRA!',
                },
            },
        }
    },
};
