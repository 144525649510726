import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SseService implements OnDestroy {
    private readonly subscriptions = new Subscription();

    constructor(private readonly _zone: NgZone) {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getServerSentEvent<Interface>(url: string): [Observable<MessageEvent<Interface>>, EventSource] {
        const eventSource = this.getEventSource(url);
        return [
            new Observable((observer) => {
                eventSource.onmessage = (event: MessageEvent) => {
                    this._zone.run(() => {
                        observer.next({ ...event, data: JSON.parse(event.data) });
                    });
                };
                eventSource.onerror = (error) => {
                    this._zone.run(() => {
                        observer.error(error);
                    });
                };
            }),
            eventSource,
        ];
    }

    private getEventSource(url: string): EventSource {
        return new EventSource(url);
    }
}
