<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
  <span class="relative">
    <ngx-avatars
        [src]="user()?.avatar?.url"
        [name]="user()?.kFullName()"
        [size]="24"
        [initialsSize]="2"
    ></ngx-avatars>
  </span>
</button>
<mat-menu [xPosition]="'before'" #userActions="matMenu" class="bottom arrow-right fab-button">
    <div
        mat-menu-item
        class="flex flex-row justify-start toolbar-user-logged m-0 px-3 py-1"
    >
        <span class="user-logged">{{ user()?.kFullName() }}</span>
    </div>
    @if (clinicSelected()) {
        <div class="flex flex-row justify-start items-center" mat-menu-item>
            <ngx-avatars
                (click)="$event.stopPropagation()"
                [src]="clinicSelected()?.logo?.url"
                [initialsSize]="2"
                name="{{ clinicSelected().name }}"
                size="30"
                class="mr-3 avatar-laboratory"
            ></ngx-avatars>
            <div class="flex flex-col items-start justify-center w-auto" >
                <span (click)="$event.stopPropagation()">{{ clinicSelected().name }}</span>
                @if (clinicList().length > 1) {
                    <div class="flex flex-row">
                        <a (click)="selectClinic()" class="text-color-blue">{{ 'USER.MENU.CHANGE' | translate }}</a>
                    </div>
                }
            </div>
        </div>
    }
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon svgIcon="logout"></mat-icon>
        <span>{{ 'USER.MENU.LOGOUT' | translate }}</span>
    </button>
</mat-menu>
