export const locale = {
    lang: 'pt',
    data: {
        NAV: {
            PRODUCTION: {
                TITLE: 'Produção',
                ORDER: 'Pedidos',
            },
            PEOPLE: {
                PATIENT: 'Pacientes',
            }
        },
    },
};
