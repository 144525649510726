export const locale = {
    lang: 'en',
    data: {
        TOOLBAR_NOTIFICATION_CHAT: {
            EMPTY: 'You have no unread messages',
            LABORATORY: 'Laboratory: {{ labName }}',
            TITLE_MESSAGES: 'Unread messages in orders',
            GO_TO_CHAT: 'Go to chat',
            MAKE_AS_READ: 'Mark as read',
            TOASTR: {
                CLEAR: 'All messages have been marked as read',
            }
        },
    },
};
