@defer (when !isLoading()) {
    @if (renderChatBadges().length === 0) {
        <k-empty
            class='flex flex-row justify-center items-center h-full'
            [size]='80'
            [description]="'TOOLBAR_NOTIFICATION_CHAT.EMPTY' | translate"
            descriptionAlign='center'
            customPaddingBottom='2px'
            imageUrl='assets/images/chat/placeholder-empty.svg'
        >
        </k-empty>
    } @else {
        <div class='m-[-24px] container'>
            <p class='flex flex-row items-center justify-between m-0 border-bottom'>
                {{ 'TOOLBAR_NOTIFICATION_CHAT.TITLE_MESSAGES' | translate }}
                <k-button
                    type='transparent'
                    class="mr-[-10px]"
                    (click)='clearBadges()'
                    [loading]='isLoadingClearBadge()'
                    [padding]="0"
                >
                    {{ 'TOOLBAR_NOTIFICATION_CHAT.MAKE_AS_READ' | translate }}
                </k-button>
            </p>
            @for (chat of renderChatBadges(); track $index) {
                <div class='flex flex-col border-bottom'>
                    <div class='flex flex-row justify-between items-center'>
                        <div>
                            <h3 class='m-0'>{{ chat?.order?.number }}</h3>
                            <p class='m-0'>
                                {{ chat?.order?.laboratory?.name }}
                            </p>
                        </div>
                        <span [matBadge]='chat?.quantity' matBadgeSize='medium' matBadgeColor='warn' class='mr-3'></span>
                    </div>
                    <div class='flex flex-row justify-between items-center mt-4'>
                        <a
                            [routerLink]="chat.routerLink"
                            [queryParams]='chat.queryParams'
                            (click)='sidebarService.close()'>
                            {{ 'TOOLBAR_NOTIFICATION_CHAT.GO_TO_CHAT' | translate }}
                        </a>
                        <span class='date'>{{ chat?.date }}</span>
                    </div>
                </div>
            }
            @if (isLoadingPage()) {
                <div class='flex flex-row justify-center items-center p-4 footer-loader w-full'>
                    <mat-spinner diameter='25' strokeWidth='2' class='loader'></mat-spinner>
                </div>
            }

        </div>
    }
} @placeholder (minimum 500) {
    <div class='m-[-24px] container h-full overflow-hidden'>
        <p class='flex flex-row items-center justify-between m-0 border-bottom'>
            <ngx-skeleton-loader [theme]="{ 'width.px': 180, 'height.px': 16 }" />
            <ngx-skeleton-loader [theme]="{ 'width.px': 100, 'height.px': 28 }" />
        </p>
        <ng-container *ngTemplateOutlet="skeletonLine" />
        <ng-container *ngTemplateOutlet="skeletonLine" />
        <ng-container *ngTemplateOutlet="skeletonLine" />
        <ng-container *ngTemplateOutlet="skeletonLine" />
        <ng-container *ngTemplateOutlet="skeletonLine" />
        <ng-container *ngTemplateOutlet="skeletonLine" />
        <ng-container *ngTemplateOutlet="skeletonLine" />
        <ng-container *ngTemplateOutlet="skeletonLine" />
        <ng-container *ngTemplateOutlet="skeletonLine" />
        <ng-container *ngTemplateOutlet="skeletonLine" />
    </div>
}

<ng-template #skeletonLine>
    <div class='flex flex-col border-bottom'>
        <div class='flex flex-row justify-between items-center'>
            <div class="flex flex-col items-start justify-start">
                <ngx-skeleton-loader [theme]="{ 'width.px': 40, 'height.px': 16 }" class="mb-2" />
                <ngx-skeleton-loader [theme]="{ 'width.px': 147, 'height.px': 16 }" />
            </div>
            <ngx-skeleton-loader [theme]="{ 'width.px': 22, 'height.px': 22, 'border-radius.px': 12 }" class="mr-3" />
        </div>
        <div class='flex flex-row justify-between items-center mt-4'>
            <ngx-skeleton-loader [theme]="{ 'width.px': 75, 'height.px': 17 }" />
            <ngx-skeleton-loader [theme]="{ 'width.px': 99, 'height.px': 17 }" />
        </div>
    </div>
</ng-template>
