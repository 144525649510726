import { AsyncPipe } from '@angular/common';
import { Component, inject, Injector, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { DateAdapter } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationError, Router, RouterModule, RouterOutlet } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { DialogService, IDialogConfig, ToastrService } from '@kiwid-app/kiwid-ui-lib-angular';
import { TranslateService } from '@ngx-translate/core';
import 'app/core/extensions/object.extensions';
import 'app/core/extensions/string.extensions';
import { ErrorHelper } from 'app/core/helpers/error.helper';
import { populateTranslateValidation } from 'app/core/helpers/form-validation/form-validation.helper';
import { ILocationTrial } from 'app/core/helpers/location-trial.helper';
import { populateTranslateNumberHelper } from 'app/core/helpers/number/number.helper';
import { parseStringToSearch } from 'app/core/helpers/util';
import { IDataPagination } from 'app/core/models/api/data';
import { ISystemFeature, ISystemMessage, IUser } from 'app/core/models/user/user';
import { AccessGroupService } from 'app/core/services/access-group/access-group.service';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { ClinicService } from 'app/core/services/clinic/clinic.service';
import { CommunicationService } from 'app/core/services/communication/communication.service';
import { MoneyService } from 'app/core/services/money/money.service';
import { UserDeviceService } from 'app/core/services/user/user-device.service';
import { UserService } from 'app/core/services/user/user.service';
import { filter, first, forkJoin, last, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EAuthenticationStorage } from './core/models/authentication/authentication';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { locale as portuguese } from './i18n/pt';
import { BaseComponent } from './layout/common/base/base.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, RouterModule, AsyncPipe],
})
export class AppComponent extends BaseComponent {
    private readonly clinicService = inject(ClinicService);
    private readonly authenticationService = inject(AuthenticationService);
    private readonly accessGroupService = inject(AccessGroupService);
    private readonly communicationService = inject(CommunicationService);
    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly moneyService = inject(MoneyService);
    private readonly toastrService = inject(ToastrService);
    private readonly userDeviceService = inject(UserDeviceService);
    private readonly userService = inject(UserService);
    private readonly dateAdapter = inject(DateAdapter);
    private readonly _fuseSplashScreenService = inject(FuseSplashScreenService);
    private readonly dialogService = inject(DialogService);
    readonly _translateService = inject(TranslateService);
    readonly title = inject(Title);

    locationsTrial: ILocationTrial[] = [];
    systemMessages: ISystemMessage[] = [];
    private injector = inject(Injector);
    protected isLoading = signal(true);
    private isLoading$ = toObservable(this.isLoading, { injector: this.injector });

    /**
     * Constructor
     */
    constructor() {
        super();
        this.setVhUnit();

        this.subscriptions.add(
            this._translateService.onLangChange.subscribe(({ lang }) => {
                const langLocalStorage = localStorage.getItem(EAuthenticationStorage.LANG) ?? 'pt';
                if (String(lang).trim() !== String(langLocalStorage).trim()) {
                    localStorage.setItem(EAuthenticationStorage.LANG, lang);
                }
                this.dateAdapter.setLocale(lang);
            }),
        );
        this.registerLoading();
        this.registerRouter();

        // Add languages
        this._translateService.addLangs(['pt', 'en', 'es']);

        // Use a language
        const language = localStorage.getItem(EAuthenticationStorage.LANG) ?? 'pt';
        this._translateService.use(language);

        this.moneyService.maskMoney = {
            decimalSeparator: ',',
            thousandSeparator: '.',
            symbol: 'R$',
            isoCode: 'BRL',
        };

        setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('es');
            this._translateService.setDefaultLang('pt');

            this.registerDefaultData();
            this.registerOnTranslate('APP', portuguese, english, spanish, false);
            populateTranslateValidation(this.baseFuseTranslationLoaderService, this.baseTranslateService);
            populateTranslateNumberHelper(this.baseFuseTranslationLoaderService, this.baseTranslateService);
        });

        // this.subscriptions.add(
        //     this.router.events.subscribe(event => {
        //         if (event instanceof NavigationEnd) {
        //             this.analytics.logEvent('pageView');
        //         }
        //     })
        // );
    }

    private registerDefaultData(): void {
        if (!!this.authenticationService.token) {
            this.authenticationService.requestNotificationPermission();
            this.userDeviceService.registerListenPushMessage();

            if (!!this.authenticationService.clinicId()) {
                this.subscriptions.add(
                    forkJoin([this.userService.me(true) as Observable<IUser>, this.clinicService.getAllWithAuth()])
                        .pipe(last())
                        .subscribe({
                            next: ([user, { data: clinics }]) => {
                                this.authenticationService.setUser(user);
                                this.authenticationService.setClinicList(clinics);

                                for (const clinic of clinics) {
                                    if (clinic.id === this.authenticationService.clinicId()) {
                                        this.authenticationService.setClinicSelected(clinic);
                                        this.registerChangeClinic();
                                    }
                                }
                            },
                            error: (error) => {
                                ErrorHelper.setToastrError(this.toastrService, error);
                                this.registerChangeClinic();
                            },
                        }),
                );
                return;
            }

            this.subscriptions.add(
                (this.userService.me(true) as Observable<IUser>).pipe(last()).subscribe({
                    next: (user) => {
                        if (window.location.pathname.search('public') > -1) {
                            this.authenticationService.logout(false);
                        } else {
                            this.authenticationService.setUser(user);
                            this.authenticationService.redirectUser();
                        }
                        this.registerChangeClinic();
                    },
                    error: (error) => {
                        ErrorHelper.setToastrError(this.toastrService, error);
                        this.registerChangeClinic();
                    },
                }),
            );

            return;
        }
        this.registerChangeClinic();
    }

    private setVhUnit(): void {
        const setVhUnit = () => {
            let vh = window.innerHeight;
            let vw = window.innerWidth;
            document.documentElement.style.setProperty('--kiwid-vh-100', `${vh}px`);

            if (vw > 959) {
                document.documentElement.style.setProperty('--kiwid-vh-content', `${vh - 52 - 54}px`);
            } else {
                document.documentElement.style.setProperty('--kiwid-vh-content', `${vh - 54}px`);
            }
        };

        setVhUnit();

        window.addEventListener('resize', setVhUnit);
    }

    private registerChangeClinic(): void {
        this.subscriptions.add(
            this.authenticationService.clinicSelectedEmit.subscribe((clinic) => {
                if (!!clinic) {
                    const baseRoute = window.location.host;
                    const isAuthOrPublic =
                        parseStringToSearch(this.router.url).indexOf(parseStringToSearch(`${baseRoute}/public`)) > -1 ||
                        parseStringToSearch(this.router.url).indexOf(parseStringToSearch(`${baseRoute}/auth`)) > -1;

                    this.isLoading.set(!isAuthOrPublic);

                    this.subscriptions.add(
                        forkJoin([this.accessGroupService.getUserPermission()])
                            .pipe(last())
                            .subscribe({
                                next: ([{ data: permissions }]) => {
                                    this.authenticationService.setPermissions(permissions);

                                    // Verify has permission customer list
                                    if (this.router.url === '/clinic/user-access') {
                                        this.authenticationService.checkRouteToRedirectUser();
                                    }

                                    this.isLoading.set(false);
                                },
                                error: (error) => {
                                    ErrorHelper.setToastrError(this.toastrService, error);
                                    this.isLoading.set(false);
                                },
                            }),
                    );

                    return;
                }

                this.isLoading.set(false);
            }),
        );
    }

    private openModalSystemFeature(data: IDataPagination<ISystemFeature[]>): void {
        // if (data.meta.pagination.total === 0) {
        //     return;
        // }
        //
        // const config: IModalConfig = {
        //     modalId: 'modal-system-feature',
        //     title: this.translateKey('MODAL.FEATURES.TITLE'),
        //     type: 'custom',
        //     width: '999px',
        //     minHeight: '85vh',
        //     buttons: [
        //         {
        //             type: 'secondary',
        //             id: 1,
        //             text: this.translateKey('MODAL.FEATURES.BUTTONS.READ_LATER'),
        //         },
        //         {
        //             type: 'primary',
        //             id: 2,
        //             text: this.translateKey('MODAL.FEATURES.BUTTONS.OK'),
        //         },
        //         {
        //             type: 'transparent',
        //             id: 3,
        //             text: this.translateKey('MODAL.FEATURES.BUTTONS.READ_ALL'),
        //         },
        //     ],
        //     component: new ComponentPortal<ModalSystemFeatureComponent>(ModalSystemFeatureComponent),
        // };
        // const modal = this.modalService.openModal(config);
        //
        // this.subscriptions.add(
        //     modal.componentInstance.instanceObservable<ModalSystemFeatureComponent>().subscribe((dataSystemFeature) => {
        //         dataSystemFeature.dialog = modal;
        //         dataSystemFeature.initialData = data;
        //     }),
        // );
    }

    private registerLoading(): void {
        this.subscriptions.add(
            this.isLoading$.subscribe((isLoading) => {
                if (isLoading) {
                    this._fuseSplashScreenService.show();
                    return;
                }

                this._fuseSplashScreenService.hide();
            }),
        );
    }

    private registerRouter(): void {
        this.subscriptions.add(
            this.router.events
                .pipe(
                    filter((evt) => evt instanceof NavigationError),
                    map((evt) => evt as NavigationError),
                )
                .subscribe((event) => {
                    const url = event?.target?.url ?? event?.url ?? null;
                    const message = typeof event.error === 'string' ? event.error : event.error.message;

                    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
                    const moduleFailedMessage = /Failed to fetch dynamically imported module: [\d]+/;
                    const moduleFailedMessageString = 'Failed to fetch dynamically imported module:';

                    if (
                        String(!!message ? message : 'KIWID')
                            .toLowerCase()
                            .search(moduleFailedMessageString.toLowerCase()) > -1 ||
                        chunkFailedMessage.test(message) ||
                        moduleFailedMessage.test(message)
                    ) {
                        this.openDialogUpdateSystem(url);
                        return;
                    }
                }),
        );
    }

    private openDialogUpdateSystem(url: string | null): void {
        const config: IDialogConfig = {
            title: this.translateKey('MODAL.CHUNK_ERROR.TITLE'),
            message: this.translateKey('MODAL.CHUNK_ERROR.DESCRIPTION'),
            style: 'warning',
            showCloseButton: false,
            disableBackgroundClose: true,
            buttons: [
                {
                    id: 1,
                    type: 'primary',
                    text: this.translateKey('MODAL.CHUNK_ERROR.BUTTONS.CONFIRM'),
                },
            ],
        };

        const dialog = this.dialogService.openDialog(config);

        this.subscriptions.add(
            dialog
                .afterOpened()
                .pipe(first())
                .subscribe(() => {
                    this.subscriptions.add(
                        dialog.componentInstance.buttonEventClick.subscribe((button) => {
                            if (button.id === 1) {
                                if (!!url) {
                                    window.location.href = url ?? '/';
                                } else {
                                    window.location.reload();
                                }
                                dialog.close();
                            }
                        }),
                    );
                }),
        );
    }
}
