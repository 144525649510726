@defer (when !isLoading()) {
    @if (notifications().length > 0) {
        <div style='margin: -24px' class='container'>
            @for (notification of notifications(); track $index) {
                <div class='flex flex-row justify-between items-start border-bottom'>
                    @if (!!notification.avatar) {
                        <ngx-avatars
                            [src]='notification.avatar'
                            [name]='notification.laboratory'
                            size='35'
                            class='mr-2'
                        ></ngx-avatars>
                    }
                    <div class='flex flex-col w-full'>
                        <h3 class='m-0'>{{ notification?.title }}</h3>
                        <p class='m-0'>{{ notification?.message }}</p>
                        @if (!!notification.action?.text) {
                            <a
                                [routerLink]='notification.action?.routerLink'
                                [queryParams]='notification.action?.routerLinkQueryParam'
                                (click)='sidebarService.close()'
                            >
                                {{ notification.action?.text }}
                            </a>
                        }
                        <span class='date'>{{ notification?.date }}</span>
                    </div>
                </div>
            }
            @if (isLoadingPage()) {
                <div class='flex flex-row justify-center items-center p-4 footer-loader w-full'>
                    <mat-spinner diameter='25' strokeWidth='2' class='loader'></mat-spinner>
                </div>
            }
        </div>
    } @else {
        <k-empty
            [size]='80'
            [description]="'TOOLBAR_NOTIFICATION_USER.EMPTY' | translate"
            descriptionAlign='center'
            customPaddingBottom='10px'
            imageUrl='assets/images/notifications/notifications-empty.svg'
            class='flex flex-row w-full h-full justify-center items-center'
        >
        </k-empty>
    }
} @placeholder (minimum 500) {
    <div style='margin: -24px' class='container max-h-[120vh] overflow-hidden'>
        @for(item of skeletonCount(); track item) {
            <div class='flex flex-row justify-between items-start border-bottom'>
                <ngx-skeleton-loader [theme]="{ 'width.px': 35, 'height.px': 35, 'border-radius.px': 17.2 }" class="mr-2"  />
                <div class='flex flex-col w-full'>
                    <ngx-skeleton-loader [theme]="{ 'width.px': 100, 'height.px': 20 }" />
                    <ngx-skeleton-loader [theme]="{ 'width.px': 320, 'height.px': 36 }" class="mt-[2px]" />
                    <ngx-skeleton-loader [theme]="{ 'width.px': 55, 'height.px': 20 }" class="mt-[2px]" />
                    <ngx-skeleton-loader [theme]="{ 'width.px': 100, 'height.px': 17 }" class='mt-[14px]' />
                </div>
            </div>
        }
    </div>
}
