<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<!-- Navigation -->
@if (isScreenSmall()) {
    <fuse-vertical-navigation
        class='dark bg-brand-main-dark print:hidden'
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]='navigationDefault()'
        [opened]='false'>
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class='flex items-center h-20 pt-2 px-8'>
                <img
                    style='width: 120px'
                    src='assets/images/logos/kiwid.svg'
                    alt='Kiwid'>
            </div>
        </ng-container>
    </fuse-vertical-navigation>
}
<!-- Wrapper -->
<div class='flex flex-col flex-auto items-center w-full h-full min-h-0 min-w-0 bg-neutral-lightest dark:bg-card'>
    <!-- Header -->
    <div
        class='relative flex flex-col flex-0 justify-center w-full  overflow-hidden z-49 shadow dark:shadow-none print:hidden'>
        <!-- Top bar -->
        <div class='relative dark flex flex-auto justify-center w-full px-5 h-[54px] bg-neutral-white'>
            <div class='flex items-center w-full'>
                <!-- Logo -->
                @if (!isScreenSmall()) {
                    <div class='flex items-center'>
                        <img
                            style='width: 120px'
                            src='assets/images/logos/kiwid.svg'
                            alt='Kiwid'>
                    </div>
                }
                <!-- Navigation toggle button -->
                @if (isScreenSmall()) {
                    <button
                        mat-icon-button
                        (click)="toggleNavigation('mainNavigation')">
                        <mat-icon [svgIcon]="'heroicons_outline:bars-3'" class='text-brand-main-darkest'></mat-icon>
                    </button>
                }
                <!-- Components -->
                <div class='flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2 text-brand-main-dark toolbar-icons'>
<!--                    @if (isShowSupport()) {-->
<!--                        <support></support>-->
<!--                    }-->
<!--                    @if (clinicSelected()) {-->
<!--                        <exports [userBadges]='userBadges()'></exports>-->
<!--                    }-->
                    @if (clinicSelected()) {
                        <messages [userBadges]='userBadges()'></messages>
                    }
                    @if (clinicSelected()) {
                        <notifications [userBadges]='userBadges()'></notifications>
                    }
<!--                    @if (enableSettings) {-->
<!--                        <button-->
<!--                            mat-icon-button-->
<!--                            routerLink='settings'>-->
<!--                            <mat-icon svgIcon='feather:settings'></mat-icon>-->
<!--                        </button>-->
<!--                    }-->
                    <languages></languages>
                    <user></user>
                </div>
            </div>
        </div>
        <!-- Bottom bar -->
        @if (!isScreenSmall() && navigationDefault().length > 0) {
            <div class='flex flex-auto justify-center shadow px-0 md:px-0 bg-brand-main-darker'>
                <div class='relative flex items-center w-full h-52px'>
                    <fuse-horizontal-navigation
                        [name]="'mainNavigation'"
                        [navigation]='navigationDefault()'></fuse-horizontal-navigation>
                </div>
            </div>
        }
    </div>
    @if (enableNotification && systemMessages().length > 0) {
        <div id='notifications' class='flex flex-col w-full'>
            @for (systemMessage of systemMessages(); track systemMessage.id; let iSystemMessage = $index) {
                <k-notification
                    [message]='systemMessage.message'
                    [type]='systemMessage.layoutType'
                    [disableBorderBottomRadius]='iSystemMessage !== systemMessages().length - 1'
                    (clickClose)='clickCloseSystemMessage(iSystemMessage)'
                ></k-notification>
            }
        </div>
    }
    <!-- Content -->
    <div class="flex flex-auto justify-center w-full">
        <div class="flex flex-col flex-auto w-full h-full enterprise-content k-scroll" #refDivScroll>
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
            Otherwise, layout changes won't be registered and the view won't be updated! -->
            <!--            <div class="h-400 min-h-400 max-h-400">-->
            @if (true) {
                <router-outlet></router-outlet>
            }
            <!--            </div>-->
        </div>
    </div>
</div>

