import { EnvironmentProviders, Provider } from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { environment } from 'environments/environment';

export const firebaseProviders = (): Array<Provider | EnvironmentProviders> => {
    return [
        { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideMessaging(() => getMessaging()),
        provideAnalytics(() => getAnalytics())
    ];
};
