export const locale = {
    lang: 'es',
    data: {
        NOTIFICATIONS: {
            SIDEBAR: {
                TITLE: 'Notificaciones',
            },
        },
    },
};
