import { FuseNavigationItem } from '@fuse/components/navigation';
import { EAclFunction, EAclModule } from 'app/core/permissions/enum/access.enum';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'order',
        title: 'PRODUCTION.ORDER',
        type: 'basic',
        link: 'production/order',
        icon: 'feather:package',
        permissions: {
            routeAccess: [{ mod: EAclModule.ORDER, fns: [EAclFunction.LIST] }],
        },
    },
    {
        id: 'patient',
        title: 'PEOPLE.PATIENT',
        type: 'basic',
        link: 'people/patient',
        icon: 'feather:user',
        permissions: {
            routeAccess: [{ mod: EAclModule.PATIENT, fns: [EAclFunction.LIST] }],
        },
    },
];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'insight',
        title: 'Insights',
        type: 'basic',
        icon: 'mat_outline:timeline',
        link: '/example',
    },
];
