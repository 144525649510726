import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IData } from '../../models/api/data';
import { IError } from '../../models/error/error';
import { IAccess } from '../../permissions/model/access';

const urlGetAllAccessGroup = () => ['lab', 'admin', 'access', 'access-group'];
const urlGetAlLAccessGroupEmployee = (accessGroupId: string) => ['lab', 'admin', 'access', 'access-group', accessGroupId, 'employee'];

@Injectable({
    providedIn: 'root',
})
export class AccessGroupService {
    constructor(private readonly router: Router, private readonly httpClient: HttpClient) {}

    // CLINIC
    getUserPermission(): Observable<IData<IAccess[]>> {
        const baseUrl = ['clinic', 'admin', 'access', 'access-group-permission', 'user'];

        const url = this.router.createUrlTree(baseUrl);

        const parsedUrl = `${environment.apiUrl}/${environment.apiVersion}${url}`;

        return this.httpClient.get(parsedUrl, { responseType: 'json' }).pipe(
            map((res: IData<IAccess[]>) => {
                return res;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof ErrorEvent) {
                    return throwError(() => `Error: ${error.error.message}`);
                }
                const errorApi: IError = error.error.error;
                return throwError(() => errorApi);
            }),
        );
    }
}
