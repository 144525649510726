interface Object {
    kFullName(): string;
}

interface Object {
    kAvatarUrl(): string | null;
}

Object.defineProperty(Object.prototype, 'kFullName', {
    value: function(): string | null {
        if (!this) {
            return null;
        }

        if (!!this.name) {
            return this.name;
        }

        return `${this.firstname ?? ''} ${this.lastname ?? ''}`.trim();
    },
});

Object.defineProperty(Object.prototype, 'kAvatarUrl', {
    value: function(): string | null {
        if (!this?.avatar?.url) {
            return null;
        }

        return this.avatar.url;
    },
});
