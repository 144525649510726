export const locale = {
    lang: 'pt',
    data: {
        TOOLBAR_NOTIFICATION_USER: {
            EMPTY: 'Você não possui notificações',
            DATE_TIME: 'HH:mm',
            SEE_ORDER: 'Ver pedido',
            SEE_ORDER_RATING: 'Ver avaliação',
            SEE_CUSTOMER_RELATIONSHIP: 'Ver tarefa',
            SEE_PRODUCT: 'Ver produto',
            SEE_INVENTORY_ORDER: 'Ver compra',
        },
    },
};
