export const locale = {
    lang: 'es',
    data: {
        TOOLBAR_NOTIFICATION_CHAT: {
            EMPTY: 'Usted no tiene mensajes sin leer',
            LABORATORY: 'Laboratorio: {{ labName }}',
            TITLE_MESSAGES: 'Mensajes no leídos en pedidos',
            GO_TO_CHAT: 'Ir al chat',
            MAKE_AS_READ: 'Marcar como leído',
            TOASTR: {
                CLEAR: 'Mensajes marcados como leídos',
            }
        },
    },
};
