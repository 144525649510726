export const locale = {
    lang: 'es',
    data: {
        NAV: {
            PRODUCTION: {
                TITLE: 'Producción',
                ORDER: 'Pedidos',
            },
            PEOPLE: {
                PATIENT: 'Pacientes',
            }
        },
    },
};
