import { Injectable } from '@angular/core';
import { NativeDateAdapter } from "@angular/material/core";


@Injectable()
export class CustomDateAdapter extends NativeDateAdapter  {
    getFirstDayOfWeek(): number {
        return 0;
    }

    getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
        return super.getDayOfWeekNames('short').map((name) => name.at(0));
    }
}


