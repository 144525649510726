import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    handleError(error: any): void {
        const changedFailedMessage = /ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked./;
        if (changedFailedMessage.test(error.message)) {
            return;
        }

        console.error(error);
    }

}
