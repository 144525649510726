export const locale = {
    lang: 'es',
    data: {
        TOOLBAR_NOTIFICATION_USER: {
            EMPTY: 'Usted no tiene notificaciones',
            DATE_TIME: 'HH:mm',
            SEE_ORDER: 'Ver pedido',
            SEE_ORDER_RATING: 'Ver evaluación',
            SEE_CUSTOMER_RELATIONSHIP: 'Ver tarea',
            SEE_PRODUCT: 'Ver producto',
            SEE_INVENTORY_ORDER: 'Ver compra',
        },
    },
};
