<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
@if (layout === 'empty') {
  <empty-layout />
}
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with horizontal navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Centered -->
@if (layout === 'centered') {
  <centered-layout></centered-layout>
}
<!-- Enterprise -->
@if (layout === 'enterprise') {
  <enterprise-layout />
}
<!-- Material -->
@if (layout === 'material') {
  <material-layout></material-layout>
}
<!-- Modern -->
@if (layout === 'modern') {
  <modern-layout></modern-layout>
}
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Classic -->
@if (layout === 'classic') {
  <classic-layout></classic-layout>
}
<!-- Classy -->
@if (layout === 'classy') {
  <classy-layout></classy-layout>
}
<!-- Compact -->
@if (layout === 'compact') {
  <compact-layout></compact-layout>
}
<!-- Dense -->
@if (layout === 'dense') {
  <dense-layout></dense-layout>
}
<!-- Futuristic -->
@if (layout === 'futuristic') {
  <futuristic-layout></futuristic-layout>
}
<!-- Thin -->
@if (layout === 'thin') {
  <thin-layout></thin-layout>
}
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Settings drawer - Remove this to remove the drawer and its button -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<fuse-drawer [fixed]="true" [mode]="'over'" [name]="'k-sidebar'" class="k-sidebar" [position]="'right'" [transparentOverlay]="false">
  <k-sidebar></k-sidebar>
</fuse-drawer>
