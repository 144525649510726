export const locale = {
    lang: 'es',
    data: {
        USER: {
            MENU: {
                CHANGE: 'Cambiar',
                LOGOUT: 'Salir',
                PLAN_PAYMENTS: 'Plan de pago',
                LOCATION_STATUS: {
                    TRIAL_EXPIRES: 'TRIAL Caduca EN {{ days }} DIAS. ADQUIERALO!',
                    TRIAL_EXPIRES_ONE_DAY: 'TRIAL CADUCA EN {{ days }} DIA. ADQUIERALO!',
                },
            },
        }
    },
};
