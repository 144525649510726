import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import { ApplicationConfig, ErrorHandler, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { provideFuse } from '@fuse';
import { ToastrModule } from '@kiwid-app/kiwid-ui-lib-angular';
import { TranslateModule } from '@ngx-translate/core';
import { appRoutes } from 'app/app.routes';
import { CustomDateAdapter } from 'app/core/adapter/custom.date.adapter';
import { provideIcons } from 'app/core/icons/icons.provider';
import { EAuthenticationStorage } from 'app/core/models/authentication/authentication';
import { firebaseProviders } from 'app/core/providers/firebase.provider';
import { mockApiServices } from 'app/mock-api';
import player from 'lottie-web';
import { provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';
import { provideNgxMask } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GlobalErrorHandler } from './core/handler/global-error.handler';
import { httpInterceptor } from './core/helpers/https.interceptor';

registerLocaleData(localePt);
registerLocaleData(localeEn);
registerLocaleData(localeEs);

const getCurrentLocale = () => {
    const currentLang = localStorage.getItem(EAuthenticationStorage.LANG);
    const lang = {
        pt: 'pt-BR',
        es: 'es',
        en: 'en-US',
    };
    return lang[currentLang] ?? currentLang ?? 'pt-BR';
};

export const MY_FORMATS_CALENDAR = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideHttpClient(withInterceptors([httpInterceptor])),
        provideRouter(appRoutes, withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })),

        importProvidersFrom(
            TranslateModule.forRoot(),
            ToastrModule.forRoot(),
            NgxSkeletonLoaderModule.forRoot({
                animation: 'progress',
                theme: {
                    extendsFromRoot: true,
                    display: 'block',
                    background: 'rgb(239,241,246) no-repeat',
                    'margin-bottom': '0',
                    cursor: 'default',
                },
            }),
        ),

        // Lottie
        provideLottieOptions({
            player: () => player,
        }),
        provideCacheableAnimationLoader(),

        // Firebase
        firebaseProviders(),

        // Mask
        provideNgxMask(),

        // Material Date Adapter
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '250ms' } },
        { provide: LOCALE_ID, useValue: getCurrentLocale() },
        { provide: MAT_DATE_LOCALE, useValue: getCurrentLocale() },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_CALENDAR },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },

        // Fuse
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            fuse: {
                layout: 'enterprise',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-brand',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
    ],
};

