export enum ENotificationActionKey {
    PROFILE = 'profile',
    ADDRESS = 'address',
    CLINIC = 'clinic',
    INVENTORY_ORDER_DETAIL = 'inventory_order_detail',
    ORDER = 'order',
    ORDER_DETAIL = 'order_detail',
    ORDER_FILE = 'order_file',
    ORDER_APPROVAL = 'order_approval',
    CUSTOMER_RELATIONSHIP = 'customer_relationship',
    ORDER_CHAT = 'order_chat',
    ORDER_RATING = 'order_rating',
    ORDER_TRACKING = 'order_tracking',
    LOCATION = 'location',
    LOCATION_DETAIL = 'location_detail',
    FINANCIAL = 'financial',
    FINANCIAL_DETAIL = 'financial_detail',
    CREDIT_CARD = 'credit_card',
    PRODUCT_REORDER_POINT = 'kiwid_product_reorder_point',
}
