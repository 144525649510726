<div class="container-sidebar" [class.disable-button]="!config()?.buttons || config()?.buttons?.length == 0">
    <k-title-model-sidebar (closeEvent)="closeSidebar()" [type]="config()?.typeHeader">{{ config().title }} </k-title-model-sidebar>
    @if (config().component) {
        <div [ngClass]="{ content: true, 'p-6': getPaddingEnabled }" (scroll)="scrollEvent($event)">
            <ng-template (attached)="componentRef($event)" [cdkPortalOutlet]="config().component"></ng-template>
        </div>
    }
    @if (config()?.buttons?.length > 0) {
        <div class="flex flex-col items-center xs:justify-center md:flex-row md:items-center md:justify-end p-4 footer">
            @for (button of config().buttons; track button.id) {
                <k-button (click)="clickButton(button)" [loading]="!!button.isLoading" id="button-dialog-custom-{{ button.id }}" [type]="button.type">
                    {{ button.text }}
                </k-button>
            }
        </div>
    }
</div>
