import { BooleanInput } from '@angular/cdk/coercion';
import { booleanAttribute, Component, computed, inject, input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink, RouterLinkWithHref } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { BaseComponent } from 'app/layout/common/base/base.component';
import { AvatarModule } from 'ngx-avatars';
import { Subject } from 'rxjs';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { locale as portuguese } from './i18n/pt';
import { IClinic } from 'app/core/models/user/user';

@Component({
    selector: 'user',
    standalone: true,
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    exportAs: 'user',
    imports: [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        AvatarModule,
        RouterLinkWithHref,
        TranslateModule,
        RouterLink,
    ],
})
export class UserComponent extends BaseComponent implements OnInit, OnDestroy {
    // inject
    private _router = inject(Router);
    private readonly authenticationService = inject(AuthenticationService);
    // private readonly freshChatService = inject(FreshChatService);

    /* eslint-disable @typescript-eslint/naming-convention */
    // tslint:disable-next-line:variable-name
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    public readonly showAvatar = input(true, { transform: booleanAttribute });
    protected user = computed(() => this.authenticationService.user());
    protected clinicList = computed<IClinic[]>(() => this.authenticationService.clinicList());
    protected clinicSelected = computed<IClinic | null>(() => this.authenticationService.clinicSelected());

    /**
     * Constructor
     */
    constructor() {
        super();
        this.registerOnTranslate('USER', portuguese, english, spanish, false);

        this.registerIcons([
            {
                iconName: 'c_arrow_circle_right',
                url: 'assets/icons/custom/arrow_circle_right.svg',
            },
        ]);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // this.user.set(this.authenticationService.userLogged());

        // this.authenticationService.userObservable.pipe(takeUntil(this._unsubscribeAll)).subscribe((user) => {
        //     this.user.set(user);
        //
        //     // Mark for check
        //     this._changeDetectorRef.markForCheck();
        // });

        this.registerClinicSelected();
    }

    /**
     * On destroy
     */
    override ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        super.ngOnDestroy();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    registerClinicSelected(): void {
        this.authenticationService.clinicSelectedEmit.subscribe((clinic) => {
            if (!!clinic) {

            }
        });
    }


    selectClinic(): void {
        // this.freshChatService.destroy();
        this._router.navigate(['/clinic/user-access']).then();
    }

    /**
     * Sign out
     */
    signOut(): void {
        // this.freshChatService.destroy();
        this.authenticationService.logout();
    }
}
