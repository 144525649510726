export const locale = {
    lang: 'pt',
    data: {
        TOOLBAR_NOTIFICATION_CHAT: {
            EMPTY: 'Você não possui mensagens não lidas',
            LABORATORY: 'Laboratório: {{ labName }}',
            TITLE_MESSAGES: 'Mensagens não lidas em pedidos',
            GO_TO_CHAT: 'Ir para o chat',
            MAKE_AS_READ: 'Marcar como lidas',
            TOASTR: {
                CLEAR: 'Mensagens marcadas como lidas',
            }
        },
    },
};
