export const locale = {
    lang: 'en',
    data: {
        NAV: {
            PRODUCTION: {
                TITLE: 'Production',
                ORDER: 'Orders',
            },
            PEOPLE: {
                PATIENT: 'Patients',
            }
        },
    },
};
