import { environment } from '../../environments/environment';

export const locale = {
    lang: 'es',
    data: {
        APP: {
            TITLE: environment.titleBase,
            MODAL: {
                FEATURES: {
                    TITLE: 'Novedades',
                    BUTTONS: {
                        READ_LATER: 'Leer más tarde',
                        OK: 'Ok, entendido',
                        READ_ALL: 'Leer todo',
                    },
                },
                SEARCH_ORDER: {
                    TITLE: 'Buscar pedido',
                    BUTTONS: {
                        SEARCH: 'Buscar',
                        CANCEL: 'Cancelar',
                    },
                },
                PEOPLE: {
                    TITLE: 'Buscar persona',
                    BUTTONS: {
                        CANCEL: 'Cancelar',
                    },
                },
                CHUNK_ERROR: {
                    TITLE: 'Actualización del sistema',
                    DESCRIPTION: 'Se ha producido un error al cargar una parte de la aplicación. Por favor, recargue la página para continuar.',
                    BUTTONS: {
                        CONFIRM: 'Recargar',
                    }
                }
            },
        },
    },
};
