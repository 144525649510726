import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CountrySettingHelper } from 'app/core/helpers/country-setting.helper';
import { FuseTranslationLoaderService } from 'app/core/translate/translation-loader.service';
import { BehaviorSubject, Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, filter, first, last, switchMap, take } from 'rxjs/operators';
import { EStatusCode, EStatusCodeText } from '../enums/status-code.enum';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { locale as portuguese } from './i18n/pt';
import { DialogService, IDialogConfig, IDialogTable, ModalService } from '@kiwid-app/kiwid-ui-lib-angular';

const isRefreshToken = signal(false);
const refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);

export type IHttpInterceptorInject = {
    authenticationService: AuthenticationService,
    dialogService: DialogService,
    modalService: ModalService,
    translateService: TranslateService,
    router: Router
}

export const httpInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authenticationService = inject(AuthenticationService);
    const countrySettingHelper = inject(CountrySettingHelper);
    const fuseTranslationLoaderService = inject(FuseTranslationLoaderService);
    const router = inject(Router);
    const modalService = inject(ModalService);
    const dialogService = inject(DialogService);
    const translateService = inject(TranslateService);
    fuseTranslationLoaderService.loadTranslations(portuguese, english, spanish);

    let dupReq = req.clone({});

    const jwtToken = authenticationService.token;
    const clinic = authenticationService.clinicId();
    const country = countrySettingHelper.countrySetting()?.code;
    const lang = authenticationService.lang();

    if (dupReq.url.indexOf('https://kiwid.app') < 0) {
        if (jwtToken) {
            dupReq = dupReq.clone({
                setHeaders: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            });
        }

        if (!!clinic) {
            dupReq = dupReq.clone({
                setHeaders: {
                    clinic,
                },
            });
        }

        if (!!country) {
            dupReq = dupReq.clone({
                setHeaders: {
                    country,
                },
            });
        }

        if (lang) {
            dupReq = dupReq.clone({
                setHeaders: {
                    'Accept-Language': lang,
                },
            });
        }

        if (dupReq.url.indexOf('/public') > -1) {
            dupReq = dupReq.clone({
                setHeaders: {
                    'Authorization': null
                },
            });
        }
    }

    return next(dupReq).pipe(
        catchError((error) => {
            return handlerCatchError(
                error,
                dupReq,
                next,
                {
                    authenticationService,
                    dialogService,
                    modalService,
                    translateService,
                    router
                },
            );
        }),
    );
};

const handlerCatchError = (error: any, dupReq: HttpRequest<any>, next: HttpHandlerFn, injects: IHttpInterceptorInject): Observable<HttpEvent<unknown>> => {
    const { router, modalService } = injects;
    const errorRedirect: number[] = [EStatusCode.BAD_REQUEST, EStatusCode.NOT_FOUND, EStatusCode.UNAUTHORIZED, EStatusCode.IP_FORBIDDEN];

    if (
        error instanceof HttpErrorResponse &&
        error.status === 401 &&
        !['auth/sign-in', 'auth/refresh-token', 'user/device'].some((item) => !!error?.error?.error?.path?.includes(item))
    ) {
        return handle401Error(dupReq, next, error, injects);
    } else if (error instanceof HttpErrorResponse && error.status === EStatusCode.SYSTEM_WARNING) {
        return handle499Warning(dupReq, next, error, injects);
    } else if (error instanceof HttpErrorResponse && error.status === EStatusCode.SYSTEM_WARNING_PARAMETER) {
        return handle498Warning(dupReq, next, error, injects);
    } else if (error instanceof HttpErrorResponse && error.status === EStatusCode.MAINTENANCE && !router.url.includes('error/maintenance')) {
        modalService.closeModal();
        router.navigate([`/error/maintenance`], { queryParams: { message: error.error?.error?.message ?? '' } }).then();
    } else if (error instanceof HttpErrorResponse && errorRedirect.includes(error.status)) {
        let statusCodeText = EStatusCodeText.BAD_REQUEST;

        if (error.status === EStatusCode.NOT_FOUND) {
            statusCodeText = EStatusCodeText.NOT_FOUND;
        }

        if (error.status === EStatusCode.IP_FORBIDDEN) {
            statusCodeText = EStatusCodeText.IP_FORBIDDEN;
        }

        if (error.status === EStatusCode.UNAUTHORIZED) {
            statusCodeText = EStatusCodeText.UNAUTHORIZED;
        }

        modalService.closeModal();
        router.navigate([`/error/${statusCodeText}`]).then();
    }

    return throwError(() => error);
};


const handle499Warning = (request: HttpRequest<any>, next: HttpHandlerFn, error: HttpErrorResponse, injects: IHttpInterceptorInject): Observable<HttpEvent<unknown>> => {
    const { dialogService, translateService, authenticationService } = injects;
    const systemWarningError: ISystemWarningError = error.error.error;

    return new Observable<HttpEvent<any>>((observer) => {
        const subscriptions = new Subscription();
        translateService
            .get('HELPER.ERROR_INTERCEPTOR.SYSTEM_ERROR')
            .pipe(last())
            .subscribe({
                next: (translate: any) => {
                    const config: IDialogConfig = {
                        title: systemWarningError.title,
                        message: systemWarningError.message,
                        style: 'warning',
                        buttons: [
                            {
                                id: 1,
                                type: 'primary',
                                text: translate?.WARNING?.BUTTONS?.CONFIRM,
                            },
                        ],
                        tables: [],
                    };

                    if (systemWarningError.type === 'confirm') {
                        config.buttons = [
                            {
                                id: 2,
                                type: 'secondary',
                                text: translate?.CONFIRM?.BUTTONS?.CANCEL,
                            },
                            {
                                id: 1,
                                type: 'primary',
                                text: translate?.CONFIRM?.BUTTONS?.CONFIRM,
                            },
                        ];
                        config.showCloseButton = false;
                    }

                    if (systemWarningError.properties && systemWarningError.properties.length > 0) {
                        const tableConfig: IDialogTable = {
                            enableHeaderTable: false,
                            tableConfig: {
                                paddingSide: true,
                            },
                            configColumn: [
                                {
                                    key: 'key',
                                    header: '',
                                },
                                {
                                    key: 'oldValue',
                                    header: '',
                                },
                                {
                                    key: 'newValue',
                                    header: '',
                                },
                            ],
                            items: [],
                        };

                        for (const item of systemWarningError.properties) {
                            tableConfig.items.push({
                                key: item.name,
                                oldValue: item.previousValue,
                                newValue: item.value,
                            });
                        }

                        config.tables = [tableConfig];
                    }

                    let enableError = true;
                    const dialog = dialogService.openDialog(config, { disableClose: true });

                    subscriptions.add(
                        dialog.afterOpened().subscribe(() => {
                            subscriptions.add(
                                dialog.componentInstance.buttonEventClick.subscribe((button) => {
                                    if (dialog.componentInstance.buttons?.some((button) => button.isLoading)) {
                                        return;
                                    }

                                    if (button.id === 2) {
                                        dialog.close();
                                        return;
                                    }

                                    if (button.id === 1) {
                                        button.isLoading = true;

                                        if (systemWarningError.type === 'warning') {
                                            enableError = false;
                                            observer.error(error);
                                            dialog.close();
                                            return;
                                        }

                                        subscriptions.add(
                                            next(
                                                request.clone({
                                                    setHeaders: {
                                                        Authorization: `Bearer ${authenticationService.token}`,
                                                    },
                                                    setParams: {
                                                        confirm: 'true',
                                                    },
                                                }),
                                            )
                                                .subscribe({
                                                    next: (result) => {
                                                        if (result instanceof HttpResponse) {
                                                            observer.next(result);
                                                            enableError = false;
                                                            dialog.close();
                                                        }
                                                    },
                                                    error: (newError) => {
                                                        observer.error(newError);
                                                        dialog.close();
                                                    },
                                                }),
                                        );
                                        return;
                                    }
                                }),
                            );
                        }),
                    );

                    subscriptions.add(
                        dialog.afterClosed().subscribe(() => {
                            if (enableError) {
                                observer.error(error);
                            }
                            observer.complete();
                            subscriptions.unsubscribe();
                        }),
                    );
                },
            });
    });
};

const handle498Warning = (request: HttpRequest<any>, next: HttpHandlerFn, error: HttpErrorResponse, injects: IHttpInterceptorInject): Observable<HttpEvent<unknown>> => {
    const { authenticationService, dialogService, translateService } = injects;
    const systemWarningError: ISystemWarningParameterError = error.error.error;

    return new Observable<HttpEvent<any>>((observer) => {
        const subscriptions = new Subscription();

        translateService
            .get('HELPER.ERROR_INTERCEPTOR.SYSTEM_ERROR')
            .pipe(first())
            .subscribe({
                next: (translate: any) => {
                    const config: IDialogConfig = {
                        title: systemWarningError.title,
                        message: systemWarningError.message,
                        style: 'warning',
                        buttons: [
                            {
                                id: 2,
                                type: 'secondary',
                                text: translate?.CONFIRM?.BUTTONS?.CANCEL,
                            },
                            {
                                id: 1,
                                type: 'primary',
                                text: translate?.CONFIRM?.BUTTONS?.CONFIRM,
                            },
                        ],
                        tables: [],
                    };

                    config.showCloseButton = false;

                    config.rows = systemWarningError.rows;

                    let enableError = true;
                    const dialog = dialogService.openDialog(config, { disableClose: true });

                    subscriptions.add(
                        dialog.afterOpened().subscribe(() => {
                            subscriptions.add(
                                dialog.componentInstance.buttonEventClick.subscribe((button) => {
                                    if (dialog.componentInstance.buttons?.some((button) => button.isLoading)) {
                                        return;
                                    }

                                    if (button.id === 2) {
                                        dialog.close();
                                        return;
                                    }

                                    if (button.id === 1) {
                                        if (!dialog.componentInstance.isValidValues) {
                                            dialog.componentInstance.markAllAsTouched();
                                            return;
                                        }

                                        button.isLoading = true;

                                        subscriptions.add(
                                            next(
                                                request.clone({
                                                    setHeaders: {
                                                        Authorization: `Bearer ${authenticationService.token}`,
                                                    },
                                                    setParams: {
                                                        confirm: 'true',
                                                    },
                                                    body: {
                                                        ...request.body,
                                                        ...dialog.componentInstance.rawValues,
                                                    },
                                                }),
                                            )
                                                .subscribe({
                                                    next: (result) => {
                                                        if (result instanceof HttpResponse) {
                                                            observer.next(result);
                                                            enableError = false;
                                                            dialog.close();
                                                        }
                                                    },
                                                    error: (newError) => {
                                                        observer.error(newError);
                                                        dialog.close();
                                                    },
                                                }),
                                        );
                                        return;
                                    }
                                }),
                            );
                        }),
                    );

                    subscriptions.add(
                        dialog.afterClosed().subscribe(() => {
                            if (enableError) {
                                observer.error(error);
                            }
                            observer.complete();
                            subscriptions.unsubscribe();
                        }),
                    );
                },
            });
    });
};

const handle401Error = (request: HttpRequest<any>, next: HttpHandlerFn, error: HttpErrorResponse, injects: IHttpInterceptorInject): Observable<HttpEvent<unknown>> => {
    const { authenticationService } = injects;
    const refreshToken = authenticationService.refreshToken;

    if (!refreshToken || !authenticationService.token) {
        authenticationService.logout();
        return throwError(() => error);
    }

    if (!isRefreshToken()) {
        isRefreshToken.set(true);
        refreshTokenSubject.next(null);

        return authenticationService
            .getRefreshToken({
                refreshToken,
            })
            .pipe(
                switchMap((result) => {
                    refreshTokenSubject.next(result.accessToken);
                    authenticationService.userCookie(result);
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${result.accessToken}`,
                        },
                    });
                    isRefreshToken.set(false);
                    return next(request);
                }),
                catchError((newError) => {
                    isRefreshToken.set(false);

                    if (newError instanceof HttpErrorResponse) {
                        if (!['auth/refresh-token'].some((item) => !!newError?.error?.error?.path?.includes(item)) && newError.status !== 401) {
                            return handlerCatchError(newError, request, next, injects);
                        }
                    }

                    authenticationService.logout();
                    return throwError(() => newError);
                }),
            );
    }

    return refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return next(request);
        }),
    );
};


export interface ISystemWarningError {
    error: string;
    message: string;
    path: string;
    statusCode: 499;
    timestamp: string;
    title: string;
    type: 'confirm' | 'warning';
    properties: { name: string; previousValue: string; value: string }[];
}

export type ISystemWarningParameterError = {
    error: string;
    message: string;
    path: string;
    statusCode: 498;
    timestamp: string;
    title: string;
    type: 'confirm' | 'warning';
    rows: RowSystemWarningParameter[];
};

type SystemWarningParameter =
    (InputSystemWarningParameter | OtherSystemWarningParameter | SelectSystemWarningParameter)
    & {
    label?: string;
};

type IKeyValueProps<Key, Value> = {
    key: Key;
    value: Value;
    disabled?: boolean;
};

export type ValidationSystemWarningParameter = {
    isRequired?: {
        message?: string;
    };
    number?: {
        min?: number;
        max?: number;
        message?: string;
    };
    date?: {
        min?: string;
        max?: string;
        message?: string;
    };
    disableMinute?: {
        message?: string;
    };
};

type OtherSystemWarningParameter<Key extends string = string, Value = any> = {
    validations?: Pick<ValidationSystemWarningParameter, 'isRequired'>;
} & (
    | {
    type: 'toggle';
    defaultValue?: boolean;
}
    | {
    type: 'checkbox';
    defaultValue?: boolean;
}
    | {
    type: 'radio-group';
    options: IKeyValueProps<Key, Value>[];
    defaultValue?: string;
}
    );

type InputSystemWarningParameter = {
    defaultValue?: string;
} & (
    | {
    type: 'text';
    validations?: Pick<ValidationSystemWarningParameter, 'isRequired' | 'number'>;
}
    | {
    type: 'currency';
    mask: {
        allowNegative: boolean;
        allowZero: boolean;
        nullable: boolean;
        min?: number;
        max?: number;
        align?: 'left' | 'right';
    };
    validations?: Pick<ValidationSystemWarningParameter, 'isRequired' | 'number'>;
}
    | {
    type: 'percentage';
    mask: {
        allowNegative: boolean;
        allowZero: boolean;
        nullable: boolean;
        min?: number;
        max?: number;
        align?: 'left' | 'right';
    };
    validations?: Pick<ValidationSystemWarningParameter, 'isRequired' | 'number'>;
}
    | {
    type: 'date';
    dateFormat: string;
    maskFormat: string;
    validations?: Pick<ValidationSystemWarningParameter, 'isRequired' | 'date'>;
}
    | {
    type: 'date-time';
    timezone: string;
    dateFormat: string;
    maskFormat: string;
    validations?: Pick<ValidationSystemWarningParameter, 'isRequired' | 'date' | 'disableMinute'>;
}
    | {
    type: 'number';
    min?: number;
    max?: number;
    validations?: Pick<ValidationSystemWarningParameter, 'isRequired' | 'number'>;
}
    | {
    type: 'textarea';
    rows?: number;
    validations?: Pick<ValidationSystemWarningParameter, 'isRequired'>;
}
    );

type SelectSystemWarningParameter<Key extends string = string, Value = any> =
    | {
    type: 'select';
    options: IKeyValueProps<Key, Value>[];
    validations?: Pick<ValidationSystemWarningParameter, 'isRequired'>;
    defaultValue?: string;
}
    | ({
    type: 'select-request';
    firstOption?: IKeyValueProps<Key, Value>;
    url: string[];
    map: {
        key: string;
        value: string;
    };
    parameters?: any;
    validations?: Pick<ValidationSystemWarningParameter, 'isRequired'>;
} & (
    | {
    defaultValue?: Value;
}
    | {
    firstOptionSelected?: boolean;
}
    ));

export type FieldSystemWarningParameter = {
    key: string;
    parameter: SystemWarningParameter;
    colSpan: number;
};

export type RowSystemWarningParameter = {
    fields: FieldSystemWarningParameter[];
};
