import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, inject, signal } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { IUserBadges } from 'app/core/models/user/user';
import { BaseComponent } from 'app/layout/common/base/base.component';
import { ISidebarConfig } from 'app/layout/components/sidebar/sidebar-config.interface';
import { SidebarService } from 'app/layout/components/sidebar/sidebar.service';
import { first } from 'rxjs';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { locale as portuguese } from './i18n/pt';
import { NotificationUserComponent } from './notification-user/notification-user.component';

@Component({
    selector: 'notifications',
    standalone: true,
    templateUrl: './notifications.component.html',
    // encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications',
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatBadgeModule
    ],

})
export class NotificationsComponent extends BaseComponent implements OnInit, OnDestroy {
    // Inject
    private readonly sidebarService = inject(SidebarService);

    userBadges = signal<IUserBadges>({});
    @Input({ alias: 'userBadges' }) set setUserBadges(value: IUserBadges) {
        this.userBadges.set(value);
    }

    /**
     * Constructor
     */
    constructor() {
        super();
        this.registerOnTranslate('NOTIFICATIONS', portuguese, english, spanish, false);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    clickNotification(): void {
        const config: ISidebarConfig = {
            component: new ComponentPortal<NotificationUserComponent>(NotificationUserComponent),
            typeHeader: 'primary',
            title: this.translateKey('SIDEBAR.TITLE'),
        };
        this.sidebarService.registerSidebar(config);

        this.subscriptions.add(
            this.sidebarService
                .componentRefObservable<NotificationUserComponent>()
                .pipe(first())
                .subscribe((ref) => {}),
        );
        this.sidebarService.open();
    }

}
